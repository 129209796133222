import { ITokenJWT } from 'api/type';
import AES from 'crypto-js/aes';
import EncUtf8 from 'crypto-js/enc-utf8';

export function generatePassword() {
  const minLength = 10;
  const maxLength = 12;
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  let attempt = 10;
  let password = '';

  while (attempt > 0 && !validatePasswordPolicy(password)) {
    password = '';
    const randomLength = Math.floor(Math.random() * (maxLength - minLength)) + minLength;
    for (let i = 0, n = charset.length; i < randomLength; i += 1) {
      password += charset.charAt(Math.floor(Math.random() * n));
    }
    attempt -= 1;
  }

  // fallback to fixed password after 10 attempts
  if (attempt === 0 && !validatePasswordPolicy(password)) {
    password = `Trader${Date.now()}`;
  }

  return password;
}

export function validatePasswordPolicy(password: string) {
  return isUpperCase(password) && isLowerCase(password) && isNumber(password);
}
function isLowerCase(value: string) {
  return /[a-z]+/g.test(value);
}

function isUpperCase(value: string) {
  return /[A-Z]+/g.test(value);
}

function isNumber(value: string) {
  return /\d/g.test(value);
}

const { HIDE_MESSAGE_SECRET_KEY } = process.env;
export function revealMessage(cipherText: string): string {
  try {
    if (HIDE_MESSAGE_SECRET_KEY) {
      const bytes = AES.decrypt(cipherText, HIDE_MESSAGE_SECRET_KEY);
      return bytes.toString(EncUtf8);
    }
    return cipherText;
  } catch (err) {
    return cipherText;
  }
}

export function getJWTPayload(jwt: string): string {
  // A JWT has 3 parts separated by '.'
  // The middle part is a base64 encoded JSON
  // decode the base64
  return atob(jwt.split('.')[1]);
}

export function checkAccessTokenExpired(exampleJWT: string): boolean {
  const payload = getJWTPayload(exampleJWT) as unknown as ITokenJWT;

  const expiration = new Date(payload?.exp);
  const now = new Date();
  return expiration.getTime() < now.getTime();
}

export function formatToLowerCase(text: string): string {
  if (!text) return '-';
  return text.toLowerCase();
}
