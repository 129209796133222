/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import Button from 'components/Common/Button/Button';
import ModalInputPasswordLogin from 'components/PageComponents/MFA/components/ModalInputPasswordLogin';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import fetch from 'isomorphic-unfetch';
import cookie from 'js-cookie';
import router from 'next/router';
import { authenticator } from 'otplib';
import QRCode from 'qrcode.react';
import { useCallback, useLayoutEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FieldWrapper } from 'styles/Styled';
import { login } from 'utils/auth';
import { fetchApi } from 'utils/common';
import { checkAccessTokenExpired } from 'utils/helper';
import * as Yup from 'yup';
import cookieOptions from "../utils/cookieOptions";
import nextCookies from 'next-cookies';

const SignInSchema = Yup.object().shape({
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

const TokenSchema = Yup.object().shape({
  userToken: Yup.string().required('Required').length(6),
});

const OTPInput = styled(OtpInput)`
  & input {
    color: #000;
    min-width: 40px;
    height: 40px;
  }
`;

function Login() {
  const [errorMessage, setErrorMessage] = useState('');
  const [step, setStep] = useState(1);
  const [jwtToken, setJwtToken] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [isShowQRCode, setIsShowQRCode] = useState(false);
  const [showModalEnterPassword, setShowModalEnterPassword] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [token_2fa, setToken2FA] = useState('');

  const isValidRedirectUrl = useCallback((redirectUrl?: string) => {
    if (!redirectUrl) return false;
    /** Get white list url and decode url from params */
    const whiteListUrl = process.env.WEB_KLDX_WHITE_LIST_URL;
    const decodeRedirectUrl = decodeURIComponent(redirectUrl || '');
    /** Get url origin , Ex: http://localhost:3000 */
    const normalized = new URL(decodeRedirectUrl);
    if (!normalized.origin) return false;
    /** Check if url is in white list */
    const whiteListUrls = (whiteListUrl as string).split(',').map((url) => url.trim());
    return whiteListUrls.includes(normalized.origin);
  }, []);

  useLayoutEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('redirectUrl');
    const decodeRedirectUrl = decodeURIComponent(redirectUrl || '');
    const existedToken = cookie.get('token');
    const existedRefToken = cookie.get('refreshToken');

    if (isValidRedirectUrl(decodeRedirectUrl) && existedRefToken && existedToken) {
      const hasExpired = checkAccessTokenExpired(existedToken ?? '');
      if (hasExpired) {
        router.push(`${window.location.origin}/issuers`);
        return;
      }

      window.location.href = `${decodeRedirectUrl}?token=${existedToken}&refreshToken=${existedRefToken}`;
    }
  }, [isValidRedirectUrl]);

  const handleLoginSuccess = async (result: any, values: any) => {
    console.log('login success');
    const { username } = values;
    const { token, refreshToken, mfa_secret, enable_mfa } = result;
    // only show MFA on PROD and DEMO env
    if (enable_mfa) {
      setJwtToken(token);
      cookie.set('token', token, cookieOptions);
      cookie.set('refreshToken', refreshToken, cookieOptions);
      const isProd = process.env.DEPLOYMENT_ENV === 'aws_prod_PROD';
      const [, , instance] = process.env.DEPLOYMENT_ENV?.split('_') ?? [];

      const issuer = isProd
        ? `${process.env.WEB_COMPANY_NAME} Admin`
        : `${instance} ${process.env.WEB_COMPANY_NAME} Admin`;
      if (!mfa_secret) {
        setIsShowQRCode(true);
        const mfaResponse = await fetch('/api/qr-code', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
          body: JSON.stringify(values),
        });
        if (mfaResponse.status === 200) {
          const { mfa_secret: secretKey } = await mfaResponse.json();
          const otpAuth = authenticator.keyuri(username, issuer, secretKey);
          setQrCode(otpAuth);
        } else {
          setErrorMessage('QR Code Failed. Please try again.');
        }
      } else {
        const otpAuth = authenticator.keyuri(username, issuer, mfa_secret);
        setQrCode(otpAuth);
      }
      setStep(2);
    } else {
      cookie.set('refreshToken', refreshToken, cookieOptions);
      // cookie.set('token', token, { path: '/' });
      toast(`Login successfully!`);
    }
    await login({ token });

    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('redirectUrl');
    const decodeRedirectUrl = decodeURIComponent(redirectUrl || '');

    if (redirectUrl && isValidRedirectUrl(decodeRedirectUrl)) {
      window.location.href = `${decodeRedirectUrl}?token=${token}&refreshToken=${refreshToken}`;
    }
  };

  const verifyOTP = async (body: { otpToken: string; accessToken: string }) => {
    try {
      const response = await fetchApi(`/api/mfa/verifyOTP-admin`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
      });
      const result = await response.json();
      if (response.ok) {
        handleLoginSuccess(result?.data, { username: result?.data?.user_name });
      } else {
        toast.error(result?.data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const submit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    setErrorMessage('');
    const url = '/api/login';
    try {
      const response = await fetchApi(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      });
      const result = await response.json();
      if (response.ok) {
        if (result?.token_2fa) {
          cookie.set('enable2FA', JSON.stringify(true), cookieOptions);
          setToken2FA(result?.token_2fa);
          setShowModalEnterPassword(true);
        } else {
          cookie.set('enable2FA', JSON.stringify(false), cookieOptions);

          handleLoginSuccess(result, values);
        }
      } else {
        setErrorMessage(result?.message || 'Login Failed. Please try again.');
      }
    } catch (error) {
      const { response } = error;
      const message = response?.message || 'Login Failed. Please try again.';
      setErrorMessage(response ? message : (error as Error).message);
    } finally {
      setSubmitting(false);
    }
  };

  const subMitVerifyToken = async (values, { setSubmitting }) => {
    setSubmitting(true);
    setErrorMessage('');
    const url = '/api/verify-token';
    try {
      const response = await fetchApi(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', authorization: `Bearer ${jwtToken}` },
        body: JSON.stringify(values),
      });
      if (response.status === 200) {
        const { verified, token } = await response.json();
        if (verified) {
          toast('Login Successful');
          await login({ token });
        } else {
          setErrorMessage('Token is not valid. Please try again.');
        }
      } else {
        setErrorMessage('Token is not valid. Please try again.');
      }
      setSubmitting(false);
    } catch (error) {
      const { response } = error;
      setErrorMessage(response ? 'Token is not valid. Please try again.' : (error as Error).message);
      setSubmitting(false);
    }
  };

  return (
    <div className="flex items-center h-screen">
      <div className="container flex items-center justify-center flex-1 mx-auto my-auto">
        <img alt="logo" src="/logo.png" style={{ width: 400, marginRight: '4em' }} />
        <div className="w-full max-w-xs">
          <h1 className="mb-6 font-sans text-xl font-thin font-semibold text-center">Login</h1>
          {errorMessage && (
            <div className="p-2">
              <div className="inline-flex items-center p-2 text-sm leading-none text-pink-600 bg-white rounded-full shadow text-teal">
                <span className="inline-flex items-center justify-center h-6 px-3 text-white bg-pink-600 rounded-full">
                  ERROR
                </span>
                <span className="inline-flex px-2">{errorMessage}</span>
              </div>
            </div>
          )}
          {/* Show login form */}
          {step === 1 && (
            <Formik initialValues={{ username: '', password: '' }} validationSchema={SignInSchema} onSubmit={submit}>
              {({ isSubmitting }) => (
                <Form className="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
                  <FieldWrapper className="mb-4">
                    <label className="form-label" htmlFor="username">
                      Username
                    </label>
                    <Field name="username" className="form-input" placeholder="Username" type="text" />
                    <ErrorMessage name="username" component="p" className="form-error-message" />
                  </FieldWrapper>
                  <FieldWrapper className="mb-6">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <Field name="password" className="form-input" placeholder="Password" type="password" />
                    <ErrorMessage name="password" component="p" className="form-error-message" />
                  </FieldWrapper>

                  <div className="flex items-center justify-between">
                    <Button type="submit" isLoading={isSubmitting}>
                      Sign In
                    </Button>
                    <div
                      className="text-right text-blue-600 cursor-pointer"
                      onClick={() => router.push('/forgot-password')}
                    >
                      Forgot password?
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}

          {/* Show MFA code */}
          {step === 2 && (
            <Formik initialValues={{ userToken: '' }} validationSchema={TokenSchema} onSubmit={subMitVerifyToken}>
              {({ isSubmitting, values, errors, setFieldValue }) => (
                <Form className="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
                  {isShowQRCode && (
                    <>
                      <h2 className="mb-1 text-lg font-medium leading-normal text-gray-900 title-font ">
                        Scan the QR code with your preferred authenticator app <br />
                        e.g. Google Authenticator, Authy, etc.
                      </h2>
                      <div className="flex items-center justify-center">
                        <QRCode value={qrCode} />
                      </div>
                    </>
                  )}
                  <h2 className="mb-3 text-lg font-medium leading-normal text-gray-900 title-font">
                    Enter the verification code provided by the authenticator
                  </h2>
                  <div className="flex items-center justify-between">
                    <OTPInput
                      value={values.userToken}
                      className="mb-4 text-black bg-white border border-gray-400 rounded focus:outline-none focus:border-indigo-500"
                      onChange={(otp) => setFieldValue('userToken', otp)}
                      numInputs={6}
                    />
                  </div>

                  <div className="flex items-center justify-between">
                    <Button type="submit" disabled={Object.keys(errors).length > 0} isLoading={isSubmitting}>
                      Verify
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          {showModalEnterPassword && (
            <ModalInputPasswordLogin
              OTP={otpCode}
              setOTP={setOtpCode}
              onClose={() => setShowModalEnterPassword(false)}
              onSubmit={() => verifyOTP({ otpToken: otpCode, accessToken: token_2fa })}
            />
          )}
        </div>
      </div>
    </div>
    // </Layout>
  );
}

export default Login;

export async function getServerSideProps(context) {
  const { query } = context;
  const { token } = nextCookies(context);
  const redirectUrl = query.redirectUrl;
  if(token && !redirectUrl){
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
}