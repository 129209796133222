import S from './styled';
import { ITextProps } from './type';
import React from 'react';

const Paragraph: React.FC<ITextProps> = ({
  color,
  children,
  height,
  onClick,
  radius,
  width,
  className,
  text,
  theme,
  size,
  disabled,
  level,
  textAlign,
  ...props
}) => {
  return (
    <S.Paragraph
      onClick={onClick}
      theme={theme}
      text={text}
      className={`${className}`}
      size={size}
      disabled={disabled}
      level={level}
      color={color}
      textAlign={textAlign}
      {...props}
    >
      {children}
    </S.Paragraph>
  );
};

export default Paragraph;
